// import axios from "axios";
import $ from 'jquery'; // jQuery import
import { VWORD_CONFIG } from "../config/env";


    // LT_C_AISRESC 비행제한구역 API
    // LT_C_AISPRHC  비행금지구역
    // LT_C_AISCTRC 관제권
    // LT_C_AISALTC  경계구역
    // LT_C_AISDNGC  위험구역
export function getNoFlyArea(type){
    return $.ajax({
        url: "https://api.vworld.kr/req/data?" +
            "service=data" +
            "&version=2.0" +
            "&request=GetFeature" +
            "&key="+VWORD_CONFIG.key +
            "&format=json" +
            "&size=1000" +
            "&geomFilter=BOX(0.0,0.0,99999999.999999,99999999.999999)" +
            "&domain="+VWORD_CONFIG.domain +
            "&data="+type,
        type: "GET",
        dataType: 'jsonp', //jsonp 로 요청해야 정상적으로 요청됨.. axios 에서 jsonp 를 사용하지 못하므로 부득이 ajax 사용
        headers: {"Content-Type": "application/json; charset=utf-8","Access-Control-Allow-Origin": "*"},
        success: {
        },
        error: function() {
            alert("에러 발생");
        }
    })
}
