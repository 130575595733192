// import {mapState} from "vuex";

import {mapState} from "vuex";

export const MapMixins = {
    data() {
        return {
        }
    },
    created() {
    },
    computed : {
        ...mapState({
            isLeftPosition : state => state.map.isLeftPosition,
            isRightAddVideoPosition : state => state.map.isRightAddVideoPosition,
            defaultLineValue : state => state.map.defaultLineValue,
            positionAndLienUnder : state => state.map.positionAndLienUnder,
            lngLatShowUnder : state => state.map.lngLatShowUnder,
        }),
    },
    watch: {
    },
    methods: {

    }
}
