<template>
  <div class="map-btn">
    <div class="small" style="display: contents;" @click="isFollow">
      <img v-if="!isFollowShow" src="@/assets/images/map/ic_map_follow_up.svg" class="button-icon" />
      <img v-if="isFollowShow" src="@/assets/images/map/ic_map_follow_up_selected.svg" class="button-icon" />
    </div>
  </div>
</template>

<script>
//vue 에서 videojs 사용 하기 위한 install
import {mapState} from "vuex";
import {MapMixins} from "../mixins/MapMixins";
import mapButtonEventBus from "@/components/map/mapButtonEventBus";
export default {
  name: "MapFollow",
  
  mixins : [MapMixins],
  props: {
  },
  data() {
    return {
      //버튼 전체 값 region
      isFollowShow : true,
    };
  },
  created() {
  },
  computed : {
    ...mapState({
      isFollowShowButton : state => state.map.isFollowShowButton,
    }),
    getFollowButtonTop(){
      return this.$store.getters["map/getFollowButtonTop"];
    },
  },
  watch: {
  },
  methods: {
    isFollow() {
      this.isFollowShow = !this.isFollowShow;
      mapButtonEventBus.$emit("isMapFollow", this.isFollowShow)
    },
  },
};
</script>

<style>
.map-btn6 {
  width: 54px;
  height: 54px;
}
.map-btn6 .small img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
