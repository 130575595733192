<template>
  <!-- style="z-index:2; position: absolute" :style="[{ 'top': getMapPointTop + lngLatShowUnder + positionAndLienUnder + 'px'}, isLeftPosition == true? {'left' : defaultLineValue + 'px'} : {'right' : defaultLineValue + 'px'} ]" -->
  <div class="map-btn" >
    <div class="small" style="display: contents;" @click="toggleModal">
      <img v-if="isPointCreate" src="@/assets/images/map/ic_map_point_create_selected.svg" class="button-icon" />
      <img v-if="!isPointCreate" src="@/assets/images/map/ic_map_point_create.svg" class="button-icon" />
    </div>
  </div>
</template>

<script>


import {MapMixins} from "../mixins/MapMixins";
import mapButtonEventBus from "@/components/map/mapButtonEventBus";
export default {
  name: "MapPointShow",
  components: {},
  mixins : [MapMixins],
  props: {
  },
  data() {
    return {
      //버튼 전체 값 region
      isPointCreate : false,
    };
  },
  mounted() {
  },
  created() {
    mapButtonEventBus.$on("toggleMapPoint", (isShow)=>{
      this.isPointCreate = isShow
    })
  },
  computed : {
    // getMapPointTop(){
    //   return this.$store.getters["map/getMapPointTop"];
    // },
  },
  methods: {
    toggleModal() {
      this.isPointCreate = !this.isPointCreate
      mapButtonEventBus.$emit("toggleMapPoint", this.isPointCreate)
    },


    onGetIsPointCreate(){
      return this.isPointCreate;
    },
  },
};
</script>

<style>
.map-btn2 {
  width: 54px;
  height: 54px;
}
.map-btn2 .small img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* img.button-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
</style>
