<template>
  <div  class="line-show">
    <div class="line-show-select" :class="{ 'map-active': !isShowLine }" @click="onClickShowLine(false)">{{ $t("btn-map-in-tab-current-loc") }}</div>
    <div class="line-show-select" :class="{ 'map-active': isShowLine }" @click="onClickShowLine(true)">{{ $t("btn-map-in-moving-route") }}</div>
  </div>
</template>

<script>
import {MapMixins} from "../mixins/MapMixins";
import mapButtonEventBus from "@/components/map/mapButtonEventBus";

export default {
  name: "MapPositionAndLine",
  mixins : [MapMixins],
  data() {
    return {
      isShowLine : false,
    };
  },
  watch: {
    
  },
  methods: {
    onClickShowLine(isLine){
      this.isShowLine = isLine;
      mapButtonEventBus.$emit('positionAndline', this.isShowLine)
    },
    showChangeModal(isShow) {
      if(isShow === false) {
        this.isTypeChangeModal = false;
        return;
      }
      this.isTypeChangeModal = !this.isTypeChangeModal;
    },
  },
};
</script>

<style>

img.button-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
