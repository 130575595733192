<template>
    <div class="map-btn-component">
        <div class="map-btn-list">
            <!-- 현 위치 & 이동경로 -->
            <mapPositionAndLine v-if="isShowMapPositionAndLine" />

            <!-- 맵센터 이동 버튼 -->
            <mapCenter v-if="isShowMapCenter" />

            <!-- 맵타입변경show 버튼  -->
            <mapTypeChange v-if="isShowMapTypeChange" />

            <!-- 맵좌표등록show 버튼 -->
            <mapPointShow v-if="isShowMapPoint" />

            <!-- 현장위치이동 버튼 -->
            <mapUserCenter v-if="isShowMapUserCenter" />

            <!-- 접속로그show 버튼 -->
            <mapFlightAlert v-if="isShowMapFlightAlert" />

            <!-- 공역show 버튼-->
            <mapNoFlightArea v-if="isShowMapNoFlightArea" />

            <!-- 비행중인 드론 시점 고정 버튼 -->
            <mapFollow v-if="isShowFollow" />
        </div>

        <!-- 날씨모달 -->
        <WeatherInfo v-if="isShowWeather" ref="weatherInfo" />
        <!-- 맵좌표등록 모달 -->
        <MapNewPoint  />
        <!-- 접속로그 모달 -->
        <FlightAlertModal v-if="isShowFlightAlertModal" />
        <!-- 접속로그 모달 -->
        <AirspaceLegend v-if="isShowAirspaceLegend" />
        <!-- 맵타입 모달 -->
        <MapTypeSelector/>

    </div>
</template>
<script>
import mapPositionAndLine from "@/components/map/button/MapPositionAndLine";
import mapTypeChange from "@/components/map/button/MapTypeChange";
import mapPointShow from "@/components/map/button/MapPointShow";
import mapCenter from "@/components/map/button/MapCenter";
import mapUserCenter from "@/components/map/button/MapUserCenter";
import mapFlightAlert from "@/components/map/button/MapFlightAlert";
import mapNoFlightArea from "@/components/map/button/MapNoFlightArea";
import MapTypeChange from "@/components/map/button/MapTypeChange.vue";
import MapFollow from "@/components/map/button/MapFollow.vue";

// 모달
import MapTypeSelector from "@/components/map/button/modal/MapTypeSelector.vue";
import MapNewPoint from "@/components/map/button/modal/MapNewPoint.vue";
import FlightAlertModal from "@/components/map/button/modal/FlightAlertModal.vue";
import AirspaceLegend from "@/components/map/button/modal/AirspaceLegend.vue";
import WeatherInfo from "@/components/weather/WeatherInfo";

import mapButtonEventBus from "@/components/map/mapButtonEventBus";

export default {
    name: 'MapButtonComponent',

    components: {
        MapTypeChange,
        mapPositionAndLine,
        MapTypeSelector,
        mapTypeChange,
        mapPointShow,
        mapCenter,
        MapFollow,
        mapUserCenter,
        mapFlightAlert,
        mapNoFlightArea,
        MapNewPoint,
        FlightAlertModal,
        WeatherInfo,
        AirspaceLegend
    },
    data() {
        return {
            // 현위치 & 이동경로
            isShowMapPositionAndLine: false,

            // 맵타입 변경버튼
            isShowMapTypeChange: false,
            // ??
            isShowMapPoint: false,
            // 센터이동 버튼
            isShowMapCenter: false,
            // 현장이동 버튼
            isShowMapUserCenter: false,
            // 접속로그 on/off
            isShowMapFlightAlert: false,
            // ??
            isShowMapNoFlightArea: false,

            // 날씨버튼
            isShowWeather: false,
            // 따라가기 버튼
            isShowFollow: false,
            isShowFlightAlertModal: false,
            isShowAirspaceLegend: false,
        }

    },
    created() {
        mapButtonEventBus.$on("button/FlightAlertShow", (isShow) => {
            this.isShowFlightAlertModal = isShow
        })
        mapButtonEventBus.$on("toggleAirspace", (isShow) => {
            this.isShowAirspaceLegend = isShow
        })
    },
    methods: {
        showMapPositionAndLine() {
            this.isShowMapPositionAndLine = true
        },

        showMapTypeChange() {
            this.isShowMapTypeChange = true
        },
        showMapPoint() {
            this.isShowMapPoint = true
        },
        showMapCenter() {
            this.isShowMapCenter = true
        },
        hideMapUserCenter() {
          this.isShowMapUserCenter = false
        },
        showMapUserCenter() {
            this.isShowMapUserCenter = true
        },
        showMapFlightAlert() {
            this.isShowMapFlightAlert = true
            this.isShowFlightAlertModal = true
        },
        showMapNoFlightArea() {
            this.isShowMapNoFlightArea = true
            this.isShowAirspaceLegend = true
        },
        showWeather() {
            this.isShowWeather = true
        },
        showFollow() {
            this.isShowFollow = true
        },


        /**
         * 스테이션에서 스위칭할때 사용하려고 만든
         */
        noShowMapTypeChange() {
            this.isShowMapTypeChange = false
        },
        noShowFollow() {
            this.isShowFollow = false
        },

    }
}
</script>
