<template>
  <div class="map-btn">
    <div class="small" style="display: contents" @click="toggleAirspace">
      <img v-if="isNoFlightAreaShow" src="@/assets/images/map/ic_map_noflight_area_select.svg" class="button-icon"/>
      <img v-if="!isNoFlightAreaShow" src="@/assets/images/map/ic_map_noflight_area.svg" class="button-icon"/>
    </div>
  </div>
</template>

<script>

import {MapMixins} from "../mixins/MapMixins";
import {getNoFlyArea } from "@/api/vworld.js"

import mapButtonEventBus from "@/components/map/mapButtonEventBus";
import MapPolygonData from "@/components/map/data/MapPolygonData";

export default {
  name: "MapTypeChange",
  mixins : [MapMixins],
  props: {
  },
  data() {
    return {      
      isNoFlightAreaShow : true,
      params : {
        ID_AREA_POLYGON_RESTRICTED : "LT_C_AISRESC",
        ID_AREA_POLYGON_FORBIDDEN : "LT_C_AISPRHC",
        ID_AREA_POLYGON_CONTROL : "LT_C_AISCTRC",
        ID_AREA_POLYGON_ALERT : "LT_C_AISALTC",
        ID_AREA_POLYGON_DANGER : "LT_C_AISDNGC"
      }
    };
  },
  mounted() {
    this.noFlyArea()
  },

  methods: {
    
    async getNoFlyArea(params) {
      const res = await getNoFlyArea(params)
      return res
    },
    noFlyArea() {
      for(const key in this.params) {
        this.getNoFlyArea(this.params[key])
        .then((res)=> {
          if(res.response.status === "ERROR") {
            console.error(res.response.error)
          }
          this.drawPolygon(res,key) 
        }).catch((err)=> {
          console.error(err)
        })
      }
    },
    drawPolygon(res, mapDataType) {
      const noFlyList = res.response.result.featureCollection.features

      noFlyList.forEach((noFlyArea, index)=> {
        const posList = []  
        const polygon = new MapPolygonData(); //mapping을 위한 도형 
        polygon.mapDataType = mapDataType
        polygon.setNoFlyArea(mapDataType)      
        polygon.id = index

        const latlngList = noFlyArea.geometry.coordinates[0][0]
        latlngList.forEach((latlng) => {
          const obj = { lng: latlng[0], lat: latlng[1] }
          posList.push(obj)
        })
        polygon.posList = posList
        mapButtonEventBus.$emit("drawAirspace", polygon, mapDataType)
      })
        
    },
    
    toggleAirspace() {
      this.isNoFlightAreaShow = !this.isNoFlightAreaShow
      mapButtonEventBus.$emit("toggleAirspace", this.isNoFlightAreaShow)
    },

  },
};

</script>

<style>
img.button-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
