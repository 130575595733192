<template>
    <div v-show="isShow" class="map-type-change">
        <span> 지도유형 </span>
        <button @click="closeModal">
            <img src="@/assets/images/map/ic_map_close.svg" />
        </button>
        <img class="normal" :class="{ 'map-type-imag-active': typeSelectName == vueMapType.ID_NORMAL.name }"
            @click="typeSelectItemName(vueMapType.ID_NORMAL)" />
        <img class="satellite" :class="{ 'map-type-imag-active': typeSelectName == vueMapType.ID_SATELLITE.name }"
            @click="typeSelectItemName(vueMapType.ID_SATELLITE)" />
        <img class="terrain" :class="{ 'map-type-imag-active': typeSelectName == vueMapType.ID_TERRAIN.name }"
            @click="typeSelectItemName(vueMapType.ID_TERRAIN)" />
    </div>
</template>

<style scoped>
.map-type-change {
  width: 328px;
  height: 140px;
  top: 50%;
  left: 50%;
  /* margin: -70px 0 0 -164px;
  padding: 11px 12px 19px; */
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: #000;
  z-index: 10;
}

.map-type-change > span {
  font-family: Noto Sans KR;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4rem;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.map-type-change > button {
  position: absolute;
  padding: 0px;
  width: 24px;
  height: 24px;
  right: 12px;
  top: 12px;
  border: 0px;
}

.map-type-change > button > image {
  display: contents;
}

.map-type-change > img {
  position: absolute;
  width: 80px;
  height: 45px;
  top: 51px;
  object-fit: contain;
  border-radius: 4px;
  border: solid 1px #aaaaaa;
}

.map-type-change > img.satellite {
  left: 21px;
  background-image: url(../../../../assets/images/map/map-type-change-satellite.png);
}
.map-type-change > img.terrain {
  left: 124px;
  background-image: url(../../../../assets/images/map/map-type-change-terrain.png);
}
.map-type-change > img.normal {
  left: 228px;
  background-image: url(../../../../assets/images/map/map-type-change-normal.png);
}
.map-type-change > img.map-type-imag-active {
  border: solid 1px #00c4ff;
}
</style>

<script>
import mapButtonEventBus from "@/components/map/mapButtonEventBus";
import MapType from "@/components/map/enum/MapType";

export default {
    name: 'MapTypeSelector',
    props: {
        mapType : {
            type: Object,
            default() {
                return MapType.ID_SATELLITE
            },
        },
    },
    data() {
        return {
            vueMapType: MapType,
            typeSelectName: this.mapType.name,
            isShow: false,
        }
    },
    created () {
        // typeSelectName
        mapButtonEventBus.$on('isShowMapTypeSelector', (value)=>{
          this.isShow = value
        })
    },
    methods: {
        /**
         * 지도유형 선택
         * @param type 지도유형변경
         */
        typeSelectItemName(type) {
            this.typeSelectName = type.name
            mapButtonEventBus.$emit('selector/mapTypeChange', type)
        },
        

        closeModal(){
            mapButtonEventBus.$emit('isShowMapTypeSelector', false)
        }
    }
}
</script>