<template>
  <div :class="[isPointCreate ? 'map-point-div' : 'map-point-modify-div', { 'google': loadMapName == 'google' }]"
    v-show="isPointCreate">
    <!--  주요지점 생성 화면 -->
    <template v-if="isPointCreate">
      <div class="select-image">
        <img class="create" src="@/assets/images/map/ic_map_point_create_position.svg" />
      </div>
      <div class="map-point-create">
        <div class="map-point-title">
          <span>신규 위치점 등록</span>
        </div>
        <div class="flex select-group">
          <span>구분선택</span>
          <div class="selectCont">
            <div class="select" v-for="data in pointTypeList" :class="{ active: pointSelectType == data }"
              @click="pointTypeChange(data)" :key="data">
              <span> {{ $t(getPointTypeName(data)) }} </span>
            </div>
          </div>
        </div>

        <div class="insert-data">
          <span>참고사항</span>
          <textarea @keyup="lengthLimit" maxlength="201" v-model="pointMemo"
            :placeholder="[$t('point-register-textarea-placeholder-text')]"></textarea>
          <div class="btn-wrap">
            <button class="medium point margin6" @click="createPoint"
              style="background: rgb(0, 128, 255);border-color: rgb(0, 128, 255); border-radius:5px;">
              등록하기
            </button>
            <button class="medium" @click="closeMapPointModal"
              style="border: 1px solid #ddd; color:#969696; border-radius:5px;">
              취소
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<style>
.map-point-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: auto;
}
</style>

<script>
import mapButtonEventBus from "@/components/map/mapButtonEventBus";
import {getPointTypeName} from "@/api/point";


export default {
  data() {
    return {
      /**
       * 240906 - 신현호
       * loadMapName은 전역적으로 관리하는게 편할것같음 지금은 임시로 해둠 수정해야함.
       */
      loadMapName: "kakao",
      isPointCreate: false,
      pointSelectType:'Caution',
      pointTypeList: ['Home', 'Caution', 'Danger', 'Search', 'Target', 'Station'],
      pointMemo: "",

    }
  },
  created() {
    
    
    mapButtonEventBus.$on("toggleMapPoint", (isShow) => {
      this.isPointCreate = isShow
      console.log("mapPointShow on", this.isPointCreate)
    })

    
  },
  methods: {
    closeMapPointModal() {
      mapButtonEventBus.$emit("toggleMapPoint", false)
    },
    
    pointTypeChange(select) {
      this.pointSelectType = select;
    },

    createPoint() {
      let sendData = {
        type : this.pointSelectType,
        memo : this.pointMemo
      }
      mapButtonEventBus.$emit("modal/createNewPoint",sendData)
      this.closeMapPointModal()
    },

    


    getPointTypeName,

    lengthLimit() {
      if (this.pointMemo.trim().length >= 201) {
        this.pointMemo = this.pointMemo.slice(0, -1);
        alert(this.$t("alert-message-characters-over"));
      }
    },

  }
}
</script>